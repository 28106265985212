import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Menu, MenuService } from 'src/app/services/common/menu.service';
import {NotificationsCenterService} from 'src/app/services/notifications/notifications-center.service';
import { PosthogService } from 'src/app/services/posthog/posthog.service';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent implements OnDestroy {
  public tabMenus: Menu[];
  public selectedMenu: Menu;
  public isMobileDevice = true;

  protected readonly notificationsMenuTitle = 'notificationsCenter.title';
  protected readonly isNotificationsCenterOpen$ = this.notificationsCenterService.isCenterOpen$;

  private destroy$ = new Subject<void>();
  totalUnseenCount$ = this.notificationsCenterService.unseenCountByMode$.pipe(map(({allProjects}) => allProjects));

  constructor(private menuService: MenuService, public router: Router, private posthogService: PosthogService, private notificationsCenterService: NotificationsCenterService) {
    this.tabMenus = [this.menuService.tabMenus[0], {
      icon: ['fal', 'bell'],
      showTab: true,
      title: this.notificationsMenuTitle,
      actionFn: () => this.notificationsCenterService.openCenter(),
    }, ...this.menuService.tabMenus.slice(1)];
    this.menuService.currentMenu$.pipe(takeUntil(this.destroy$))
      .subscribe(currentMenu => {
        this.selectedMenu = currentMenu;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async navigateTo(tabMenu: Menu) {
    if (tabMenu.url) {
      this.menuService.setMenu(tabMenu);
      this.router.navigate([tabMenu.url], { replaceUrl: true });
    } else if (tabMenu.actionFn) {
      tabMenu.actionFn();
    }
    this.posthogService.captureEvent('Mobile Navigate Footer', {destination: tabMenu.title});
  }
}
