import {Injectable, OnDestroy} from '@angular/core';
import {PluginListenerHandle} from '@capacitor/core';
import {ConnectionStatus, Network} from '@capacitor/network';
import _ from 'lodash';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, of, timer} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NetworkError, TimeoutError, convertErrorToMessage} from '../../shared/errors';
import {PING_TIMEOUT_IN_MS, fetchWithTimeout} from '../../utils/fetch-utils';
import {LoggingService} from './logging.service';

const LOG_SOURCE = 'NetworkStatusService';
const PING_INTERVAL_IN_MS = 15 * 1000;

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService implements OnDestroy {
  private networkStatusSubject = new ReplaySubject<ConnectionStatus|undefined>(1);
  public networkStatusObservable = this.networkStatusSubject.asObservable();
  private networkConnectedSubject = new ReplaySubject<boolean>(1);
  /** Whether networkStatus is connected. If false it's definitely not connected to the internet (e.g. Plane mode, WIFI off) but if it is true
   * it might still be possible to not being able to connected to the internet. Check $online for that case. */
  public networkConnectedObservable = this.networkConnectedSubject.asObservable();
  private networkStatusChangedSubject = new Subject<ConnectionStatus|undefined>();
  public networkStatusChangedObservable = this.networkStatusChangedSubject.asObservable();
  private lastNetworkConnectedValue: boolean|undefined;
  private networkConnectedChangedSubject = new Subject<boolean>();
  public networkConnectedChangedObservable = this.networkConnectedChangedSubject.asObservable();

  private networkStatusHandler: PluginListenerHandle|undefined;

  private lastSuccessfulServerRequestTimestampSubject = new BehaviorSubject<number|undefined>(undefined);
  private lastSuccessfulServerRequestTimestamp$: Observable<number|undefined> = this.lastSuccessfulServerRequestTimestampSubject.pipe(shareReplay(1));
  private lastFailedServerRequestTimestampSubject = new BehaviorSubject<number|undefined>(undefined);

  private ping$ = timer(0, PING_INTERVAL_IN_MS)
    .pipe(withLatestFrom(this.networkConnectedObservable, this.lastSuccessfulServerRequestTimestamp$, this.lastFailedServerRequestTimestampSubject))
    .pipe(filter(([timerValue, browserOnline, lastSuccessfulServerRequestTimestamp, lastFailedServerRequestTimestamp]) =>
      browserOnline &&
      (lastSuccessfulServerRequestTimestamp === undefined || lastSuccessfulServerRequestTimestamp < Date.now() - PING_INTERVAL_IN_MS) &&
      (lastFailedServerRequestTimestamp === undefined || lastFailedServerRequestTimestamp < Date.now() - PING_INTERVAL_IN_MS)));
  private pingSubscription: Subscription|undefined;

  private onlineSubject = new BehaviorSubject<boolean|undefined>(undefined);
  /** Whether the browser is online (proven by a successfull connection to the server) */
  public online$: Observable<boolean|undefined> = this.networkConnectedObservable
    .pipe(switchMap((browserOnline) => !browserOnline ? of(false) : this.onlineSubject.asObservable()))
    .pipe(distinctUntilChanged(_.isEqual))
    .pipe(shareReplay(1));

  public onlineOrUnknown$ = this.online$.pipe(map((online) => online === true || online === undefined))
    .pipe(distinctUntilChanged(_.isEqual))
    .pipe(shareReplay(1));

  constructor(private loggingService: LoggingService) {
    Network.getStatus().then((networkStatus: ConnectionStatus|undefined) => this.subjectsNextNetworkStatus(networkStatus));
    Network.addListener('networkStatusChange', async (networkStatus) => {
      this.loggingService.debug(LOG_SOURCE, `networkStatusChange to connected=${networkStatus?.connected}, type=${networkStatus?.connectionType}`);
      this.subjectsNextNetworkStatus(networkStatus);
      this.networkStatusChangedSubject.next(networkStatus);
      if (networkStatus !== undefined && (this.lastNetworkConnectedValue === undefined || this.lastNetworkConnectedValue !== networkStatus.connected)) {
        this.loggingService.debug(LOG_SOURCE, `networkConnectedChangedSubject.next(${networkStatus.connected})`);
        this.networkConnectedChangedSubject.next(networkStatus.connected);
      }
    }).then((networkStatusHandler) => this.networkStatusHandler = networkStatusHandler);
    this.startOnline();
    this.callPingAndHandleResult();
  }

  ngOnDestroy(): void {
    this.networkStatusHandler?.remove();
    this.pingSubscription?.unsubscribe();
  }

  private subjectsNextNetworkStatus(networkStatus: ConnectionStatus|undefined) {
    this.networkStatusSubject.next(networkStatus);
    const networkConnected: boolean = networkStatus === null || networkStatus === undefined || networkStatus.connected;
    this.loggingService.debug(LOG_SOURCE, `networkConnected=${networkConnected}`);
    this.networkConnectedSubject.next(networkConnected);
  }

  public async getNetworkStatus(): Promise<ConnectionStatus|undefined> {
    return await Network.getStatus();
  }

  private startOnline() {
    this.pingSubscription?.unsubscribe();
    this.pingSubscription = this.ping$.subscribe(async () => {
      try {
        await this.callPingAndHandleResult();
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `ping$.subscribe failed with error ${convertErrorToMessage(error)}`);
      }
    });
  }

  private async callPingAndHandleResult() {
    const pingSuccessful = await this.callPing();
    if (pingSuccessful) {
      this.lastSuccessfulServerRequestTimestampSubject.next(Date.now());
    } else {
      this.lastFailedServerRequestTimestampSubject.next(Date.now());
    }
    this.onlineSubject.next(pingSuccessful);
  }

  private async callPing(): Promise<boolean|undefined> {
    const start = Date.now();
    try {
      this.loggingService.debug(LOG_SOURCE, `callPing - starting`);
      const url = environment.serverUrl + 'ping';
      const request = new Request(url, {method: 'GET', body: undefined, keepalive: false,
        headers: {'ngsw-bypass': 'true'}});
      this.loggingService.debug(LOG_SOURCE, `callPing - fetch sent`);
      const response = await fetchWithTimeout(request, undefined, PING_TIMEOUT_IN_MS);
      if (!response.ok) {
        this.loggingService.error(LOG_SOURCE, `callPing - response.status ${response.status}`);
        return false;
      }
      this.loggingService.debug(LOG_SOURCE, `callPing - fetch received`);
      this.markSuccessfulServerRequest();
      return true;
    } catch (error) {
      if (error instanceof TimeoutError) {
        this.loggingService.error(LOG_SOURCE, `callPing - Timeout - ${convertErrorToMessage(error)}`);
        this.markFailedServerRequest();
        return false;
      }
      this.loggingService.error(LOG_SOURCE, `callPing - ${convertErrorToMessage(error)}`);
      this.markFailedServerRequest();
      return false;
    } finally {
      this.loggingService.debug(LOG_SOURCE, `callPing finished in ${Date.now() - start} ms.`);
    }
  }

  markSuccessfulServerRequest(timestamp = Date.now()) {
    this.loggingService.debug(LOG_SOURCE, 'markSuccessfulServerRequest called.');
    this.lastSuccessfulServerRequestTimestampSubject.next(timestamp);
    this.onlineSubject.next(true);
  }

  markFailedServerRequest(timestamp = Date.now()) {
    this.loggingService.debug(LOG_SOURCE, 'markFailedServerRequest called.');
    this.lastFailedServerRequestTimestampSubject.next(timestamp);
    this.onlineSubject.next(false);
  }

  isNetworkError(error: any): boolean {
    return error && (error instanceof NetworkError || error instanceof TimeoutError || ('name' in error &&  error.name === 'TimeoutError'));
  }

  markFailedServerRequestIfNetworkError(error: any): boolean {
    if (this.isNetworkError(error)) {
      this.markFailedServerRequest();
      return true;
    }
    return false;
  }

  /**
   * This checks if the app is currently marked as online.
   * Since online check is done periodically it could be that this value is outdated by PING_INTERVAL_IN_MS.
   * Use isOnline() if you want to force the online status to be checked in case it is currently not marked as online.
   */
  public get online(): boolean|undefined {
    return this.onlineSubject.value;
  }

  public get onlineOrUnknown(): boolean {
    return this.onlineSubject.value === true || this.onlineSubject.value === undefined;
  }

  public get offline(): boolean {
    return this.onlineSubject.value === false;
  }

  /**
   * This method checks if the app is currently marked as online.
   * If it is currently not marked as online it will ping the server to check if it is online.
   */
  public async isOnline(): Promise<boolean|undefined> {
    if (this.onlineSubject.value === true) {
      return true;
    }
    return await this.callPing();
  }

    /**
     * This method will ping the server to update online status of app
     */
    public async pingServer(): Promise<boolean|undefined> {
      return await this.callPing();
    }

}

